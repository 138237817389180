import Axios from "../utils/axios";
import qs from "qs";
//用户登录
export const get_token = data => {
  return Axios({
    method: "POST",
    url: "/api/authorizations/",
    data: qs.parse(data)
  });
};
//修改密码
export const get_password = data => {
  return Axios({
    method: "put",
    url: "/api/auth/password/",
    data: qs.parse(data)
  });
};
//用户基本信息
export const get_user = data => {
  return Axios({
    method: "get",
    url: "/api/user/",
    data: qs.parse(data)
  });
};
//用户退出登录
export const get_logout = () => {
  return Axios({
    method: "post",
    url: "/api/auth/logout/"
  });
};
// 邮箱重置密码功能
export const get_findpassword = data => {
  return Axios({
    method: "post",
    url: "/api/auth/findpassword/",
    data: qs.parse(data)
  });
};
//邮箱验证，修改密码
export const get_changepassword = data => {
  return Axios({
    method: "post",
    url: "/api/auth/changepassword/",
    data: qs.parse(data)
  });
};
// 根据token获取用户信息
export const get_userinfo = data => {
  return Axios({
    method: "get",
    url: "/api/auth/tokentouserinfo/",
    // responseType: 'arraybuffer',
    data: qs.parse(data)
  });
};

export const postRequestContactUsData = data => {
  return Axios({
    method: "post",
    url: "/api/user-application/",
    data: qs.parse(data)
  });
};
