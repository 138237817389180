import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
import { defineAsyncComponent } from "vue";
import store from "../store";
// import baseUrl from '../utils/baseurl'
// import { sendBeacon, base } from '../utils/error'
// const index_page = store.getters.index == "search" ? "/index.html" : "/modelbrower.html";
const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/components/login.vue")
  },
  {
    path: "/password",
    name: "password",
    component: () => import("@/components/password.vue")
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/components/register.vue')
  },
  {
    path: "/retrieve",
    name: "Retrieve",
    component: () => import("@/components/retrieve.vue")
  },
  {
    path: "/invite",
    name: "Invite",
    component: () => import("@/components/inviteUsers.vue")
  },
  {
    path: "/email",
    name: "email",
    component: () => import("@/components/email.vue")
  },
  {
    path: "/",
    name: "SearchLayout",
    component: () => import("@/layout/searchLayout.vue"),
    meta: {
      requiresAuth: true
      // page: ['search','result'],
      // query:true
    },
    // redirect:(store.getters.index=='search')?'/index.html':'/modelbrower.html',
    children: [
      {
        path: "/index.html",
        name: "search",
        component: () => import("@/views/search.vue"),
        meta: {
          page: ["search"],
					redirectFlag:true
        }
      },
      {
        path: "/modelbrower.html",
        name: "result",
        component: () => import("@/views/result.vue"),
        meta: {
          page: ["result"],
					redirectFlag:true
        }
      },
      {
        path: "/result",
        name: "search-result",
        component: () => import("@/views/result.vue"),
        meta: {
          page: ["search"],
					redirectFlag:true
        }
      },
      {
        path: "/PBMCView",
        name: "PBMCView",
        component: () => import("@/views/Comm/comm_View.vue"),
        meta: {
          page: ["search"],//页面跳转是model还是modelbrowser
          query: true,//页面跳转是否携带参数
					redirectFlag:true//页面跳转是否重定向,非model的参数采用localstorage方式存储参数，无法url获取参数发送请求
        }
      },
      {
        path: "/PDXView",
        name: "PDXView",
        component: () => import("@/views/Comm/comm_View.vue"),
        meta: {
          page: ["search"],
          query: true,
					redirectFlag:true
        }
      },
      {
        path: "/CDAView",
        name: "CDAView",
        component: () => import("@/views/Comm/comm_View.vue"),
        meta: {
          page: ["search"],
          query: true,
					redirectFlag:true
        }
      },
      {
        path: "/CDXView",
        name: "CDXView",
        component: () => import("@/views/Comm/comm_View.vue"),
        meta: {
          page: ["search"],
          query: true,
					redirectFlag:true
        }
      },
      {
        path: "/GeneView",
        name: "GeneView",
        component: () => import("@/views/Comm/comm_View.vue"),
        meta: {
          page: ["search"],
          query: true,
					redirectFlag:true
        }
      },
      {
        path: "/MutationView",
        name: "MutationView",
        component: () => import("@/views/Comm/comm_View.vue"),
        meta: {
          page: ["search"],
          query: true,
					redirectFlag:true
        }
      },
      {
        path: "/FusionView",
        name: "FusionView",
        component: () => import("@/views/Comm/comm_View.vue"),
        meta: {
          page: ["search"],
          query: true,
					redirectFlag:true
        }
      },
      {
        path: "/PathwayView",
        name: "PathwayView",
        component: () => import("@/views/Comm/comm_View.vue"),
        meta: {
          page: ["search"],
          query: true,
					redirectFlag:true
        }
      },
      {
        path: "/ImmumeCellView",
        name: "ImmumeCellView",
        component: () => import("@/views/Comm/comm_View.vue"),
        meta: {
          page: ["search"],
          query: true,
					redirectFlag:true
        }
      },
      {
        path: "/HLAView",
        name: "HLAView",
        component: () => import("@/views/Comm/comm_View.vue"),
        meta: {
          page: ["search"],
          query: true,
					redirectFlag:true
        }
      },
      {
        path: "/CDR3View",
        name: "CDR3View",
        component: () => import("@/views/Comm/comm_View.vue"),
        meta: {
          page: ["search"],
          query: true,
					redirectFlag:true
        }
      },
      {
        path: "/DrugView",
        name: "DrugView",
        component: () => import("@/views/Comm/comm_View.vue"),
        meta: {
          page: ["search"],
          query: true,
					redirectFlag:true
        }
      },
      {
        path: "/CcleView",
        name: "CcleView",
        component: () => import("@/views/Ccle/Ccle.vue"),
        meta: {
          page: ["search"],
					redirectFlag:false
        }
      },
      {
        path: "/TcgaView",
        name: "TcgaView",
        component: () => import("@/views/Tcga/Tcga.vue"),
        meta: {
          page: ["search"],
					redirectFlag:false
        }
      },
      {
        path: "/SampleView",
        name: "SampleView",
        component: () => import("@/views/Tcga/tcga_view.vue"),
        meta: {
          page: ["search"],
					redirectFlag:false
        }
      },
      {
        path: "/TcgaGeneView",
        name: "TcgaGeneView",
        component: () => import("@/views/Tcga/tcga_gene_view.vue"),
        meta: {
          page: ["search"],
					redirectFlag:false
        }
      },
      {
        path: "/TcgaMutationView",
        name: "TcgaMutationView",
        component: () => import("@/views/Tcga/tcga_mutation_view.vue"),
        meta: {
          page: ["search"],
					redirectFlag:false
        }
      },
      {
        path: "/TcgaFusionView",
        name: "TcgaFusionView",
        component: () => import("@/views/Tcga/tcga_fusion_view.vue"),
        meta: {
          page: ["search"],
					redirectFlag:false
        }
      },
      {
        path: "/TcgaTILCellView",
        name: "TcgaTILCellView",
        component: () => import("@/views/Tcga/tcga_TILCell_view.vue"),
        meta: {
          page: ["search"],
					redirectFlag:false
        }
      },
      {
        path: "/CcleSampleView",
        name: "CcleSampleView",
        component: () => import("@/views/Ccle/Ccle_View.vue"),
        meta: {
          page: ["search"],
					redirectFlag:false
        }
      },
      {
        path: "/CcleGeneView",
        name: "CcleGeneView",
        component: () => import("@/views/Ccle/ccle_gene_view.vue"),
        meta: {
          page: ["search"],
					redirectFlag:false
        }
      },
      {
        path: "/CcleMutationView",
        name: "CcleMutationView",
        component: () => import("@/views/Ccle/ccle_mutation_view.vue"),
        meta: {
          page: ["search"],
					redirectFlag:false
        }
      },
      {
        path: "/CcleFusionView",
        name: "CcleFusionView",
        component: () => import("@/views/Ccle/ccle_fusion_view.vue"),
        meta: {
          page: ["search"],
					redirectFlag:false
        }
      },
      {
        path: "/ModelView",
        name: "ModelView",
        component: () => import("@/views/SingleModelView/SingleModelView.vue"),
        meta: {
          page: ["search", "result"],
          query: true,
					redirectFlag:true
        }
      },
      {
        path: "/KassandraView",
        name: "KassandraView",
        component: () => import("@/views/Kassandra/KassandraSearchView.vue"),
        meta: {
          page: ["search"],
					redirectFlag:false
        }
      },
      {
        path: "/KassandraModelView",
        name: "KassandraModelView",
        component: () => import("@/views/Kassandra/KassandraView.vue"),
        meta: {
          page: ["search"],
					redirectFlag:false
        }
      },
      {
        path: "/KassandraGeneView",
        name: "KassandraGeneView",
        component: () => import("@/views/Kassandra/KassandraGeneView.vue"),
        meta: {
          page: ["search"],
					redirectFlag:false
        }
      },
      {
        path: "/GtexView",
        name: "GtexView",
        component: () => import("@/views/GtexView/gtexSearch.vue"),
        meta: {
          page: ["search"],
					redirectFlag:false
        }
      },
      {
        path: "/GtexSampleView",
        name: "GtexSampleView",
        component: () => import("@/views/GtexView/gtexView.vue"),
        meta: {
          page: ["search"],
					redirectFlag:false
        }
      },
      {
        path: "/GtexGeneView",
        name: "GtexGeneView",
        component: () => import("@/views/GtexView/gtexGeneView.vue"),
        meta: {
          page: ["search"],
					redirectFlag:false
        }
      },
      {
        path: "/ModelForBDView",
        name: "ModelForBDView",
        component: () => import("@/views/IndividualModelView/IndividualView.vue"),
        meta: {
          page: ["search", "result"],
          query: true,
					redirectFlag:true
        }
      }
    ]
  },
  { path: "/:pathMatch(.*)", redirect: store.getters.index == "search" ? "/index.html" : "/modelbrower.html" }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
	// 修复css丢失问题
	if (to.path === from.path) {
		window.scrollTo(0, 0)
	}
  sessionStorage.clear();
	store.commit("SET_PAGEINIT", false);
  // let startTime = Date.now()
  // let currentTime = ''
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.user.token) {
      next({
        name: "login",
				query: {
					redirect: to.fullPath
				}
      });
    } else {
      const index_page = store.getters.index;
      if (to.path == "/") {
        next({
          // name: index_page,
          name: index_page ? index_page : "login"
        });
      } else {
        next()
      }
    }
  } else {
    next();
  }
  // currentTime = Date.now()
  // stayTime = parseInt(currentTime - startTime)
  // let options = {
  //   ...base,
  //   eventType: 'page',
  //   eventId: 'router',
  //   url: window.location.href,
  //   triggerTime: Date.now(),
  //   stayTime
  // }
  // sendBeacon(url, options)
});
// seo
router.afterEach((to)=>{
	const {title, keywords, description} = to.meta
  if (title) {
    document.title = title
  }
  if (keywords) {
    const metaKeywords = document.querySelector('meta[name="keywords"]')
    if (metaKeywords) {
      metaKeywords.content = keywords
    }
  }
  if (description) {
    const metaDescription = document.querySelector('meta[name="description"]')
    if (metaDescription) {
      metaDescription.content = description
    }
  }
	store.commit("SET_PAGEINIT", true);
	// store.commit("SET_AJAXCOUNT", 0);
})
export default router;
